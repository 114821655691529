import React, { useState, useRef } from 'react';
import {observer} from "mobx-react-lite";
import Badge from 'react-bootstrap/Badge';
import {NavLink, Link} from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import {InputGroup, Button, Form} from 'react-bootstrap';

const Header = () => {
  const navigate = useNavigate();
  const [search, setSearch] = useState('');

  async function searchProducts() {
    if (search < 3) return console.log("error");
    navigate("/search/" + search);
  }

  const onKeyDown = (event) => {
    if (event.key === 'Enter') {
      event.preventDefault();
      event.stopPropagation();
      searchProducts();
    }
  }
  return (
    <div>
      <div className="px-3 py-2 text-bg-dark mt-nav">
        <div className="container">
          <div className="d-flex flex-wrap align-items-center justify-content-center justify-content-lg-start">
            <Link to="/" className="d-flex align-items-center my-2 my-lg-0 me-lg-auto text-white text-decoration-none">
              <h5 className="bi me-2">
                Мотор <Badge bg="white text-primary">Трейд</Badge>
              </h5>
            </Link>

            <ul className="nav col-12 col-lg-auto my-2 justify-content-center my-md-0 text-small">
              <li>
                <NavLink to="/" className={(navData) => navData.isActive ? "nav-link mt-nav_text_active" : "nav-link mt-nav_text"}>
                  <svg xmlns="http://www.w3.org/2000/svg" className="bi d-block mx-auto mb-1" width="24" height="24" viewBox="0 0 24 24" strokeWidth="2" stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round">
                    <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                    <path d="M5 12l-2 0l9 -9l9 9l-2 0"></path>
                    <path d="M5 12v7a2 2 0 0 0 2 2h10a2 2 0 0 0 2 -2v-7"></path>
                    <path d="M9 21v-6a2 2 0 0 1 2 -2h2a2 2 0 0 1 2 2v6"></path>
                  </svg>
                  Главная
                </NavLink>
              </li>
              {/* <li>
                <NavLink to="/products" className={(navData) => navData.isActive ? "nav-link mt-nav_text_active" : "nav-link mt-nav_text"}>
                  <svg xmlns="http://www.w3.org/2000/svg" className="bi d-block mx-auto mb-1" width="24" height="24" viewBox="0 0 24 24" strokeWidth="2" stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round">
                    <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                    <path d="M3 21l18 0"></path>
                    <path d="M3 7v1a3 3 0 0 0 6 0v-1m0 1a3 3 0 0 0 6 0v-1m0 1a3 3 0 0 0 6 0v-1h-18l2 -4h14l2 4"></path>
                    <path d="M5 21l0 -10.15"></path>
                    <path d="M19 21l0 -10.15"></path>
                    <path d="M9 21v-4a2 2 0 0 1 2 -2h2a2 2 0 0 1 2 2v4"></path>
                  </svg>
                  Каталог
                </NavLink>
              </li> */}
              <li>
                <NavLink to="/cart" className={(navData) => navData.isActive ? "nav-link mt-nav_text_active" : "nav-link mt-nav_text"}>
                  <svg xmlns="http://www.w3.org/2000/svg" className="bi d-block mx-auto mb-1" width="24" height="24" viewBox="0 0 24 24" strokeWidth="2" stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round">
                    <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                    <path d="M10 14a2 2 0 1 0 4 0a2 2 0 0 0 -4 0"></path>
                    <path d="M5.001 8h13.999a2 2 0 0 1 1.977 2.304l-1.255 7.152a3 3 0 0 1 -2.966 2.544h-9.512a3 3 0 0 1 -2.965 -2.544l-1.255 -7.152a2 2 0 0 1 1.977 -2.304z"></path>
                    <path d="M17 10l-2 -6"></path>
                    <path d="M7 10l2 -6"></path>
                  </svg>
                  Корзина
                </NavLink>
              </li>
              <li>
                <NavLink to="/pages/1" className={(navData) => navData.isActive ? "nav-link mt-nav_text_active" : "nav-link mt-nav_text"}>
                  <svg xmlns="http://www.w3.org/2000/svg" className="bi d-block mx-auto mb-1" width="24" height="24" viewBox="0 0 24 24" strokeWidth="2" stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round">
                    <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                    <path d="M10.24 3.957l-8.422 14.06a1.989 1.989 0 0 0 1.7 2.983h16.845a1.989 1.989 0 0 0 1.7 -2.983l-8.423 -14.06a1.989 1.989 0 0 0 -3.4 0z"></path>
                    <path d="M12 17v.01"></path>
                    <path d="M12 14a2 2 0 0 0 .914 -3.782a1.98 1.98 0 0 0 -2.414 .483"></path>
                  </svg>
                  Помощь
                </NavLink>
              </li>
              <li>
                <NavLink to="/contacts" className={(navData) => navData.isActive ? "nav-link mt-nav_text_active" : "nav-link mt-nav_text"}>
                  <svg xmlns="http://www.w3.org/2000/svg" className="bi d-block mx-auto mb-1" width="24" height="24" viewBox="0 0 24 24" strokeWidth="2" stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round">
                    <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                    <path d="M3 4m0 3a3 3 0 0 1 3 -3h12a3 3 0 0 1 3 3v10a3 3 0 0 1 -3 3h-12a3 3 0 0 1 -3 -3z"></path>
                    <path d="M9 10m-2 0a2 2 0 1 0 4 0a2 2 0 1 0 -4 0"></path>
                    <path d="M15 8l2 0"></path>
                    <path d="M15 12l2 0"></path>
                    <path d="M7 16l10 0"></path>
                  </svg>
                  Контакты
                </NavLink>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div className="px-3 py-2 border-bottom mb-3">
        <div className="container d-flex flex-wrap justify-content-center">
          <form className="col-12 col-lg-6 mb-2 mb-lg-0 me-lg-auto" role="search">
            <InputGroup className='w-100'>
              <Form.Control value={search} onChange={(e) => setSearch(e.target.value)} onKeyDown={onKeyDown} type="search" className="form-control input-sm" placeholder="Введите название или номер детали..." aria-label="Search" />
              <Button onClick={() => searchProducts()} size="sm" variant="outline-primary">
                <svg xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-search" width="24" height="24" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round">
                  <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                  <path d="M10 10m-7 0a7 7 0 1 0 14 0a7 7 0 1 0 -14 0"></path>
                  <path d="M21 21l-6 -6"></path>
                </svg>
              </Button>
            </InputGroup>
          </form>
          <div className="text-end">
            <a href="tel:+73462581274">
              <button type="button" className="mb-2 mb-sm-0 mb-lg-0 mb-xl-0 mb-0 btn btn-light text-dark me-2 mt-btn_contacts">
                <svg xmlns="http://www.w3.org/2000/svg" className="me-2" width="22" height="22" viewBox="0 0 22 22" strokeWidth="2" stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round">
                  <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                  <path d="M5 4h4l2 5l-2.5 1.5a11 11 0 0 0 5 5l1.5 -2.5l5 2v4a2 2 0 0 1 -2 2a16 16 0 0 1 -15 -15a2 2 0 0 1 2 -2"></path>
                  <path d="M15 7a2 2 0 0 1 2 2"></path>
                  <path d="M15 3a6 6 0 0 1 6 6"></path>
                </svg>
                <span>+7 (3462) 58-12-74</span>
              </button>
            </a>
            <a href="mailto:sales@mta86.ru">
              <button type="button" className="btn btn-light text-dark me-2 mt-btn_contacts">
                <svg xmlns="http://www.w3.org/2000/svg" className="me-2" width="24" height="24" viewBox="0 0 24 24" strokeWidth="2" stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round">
                  <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                  <path d="M12 12m-4 0a4 4 0 1 0 8 0a4 4 0 1 0 -8 0"></path>
                  <path d="M16 12v1.5a2.5 2.5 0 0 0 5 0v-1.5a9 9 0 1 0 -5.5 8.28"></path>
                </svg>
                <span>sales@mta86.ru</span>
              </button>
            </a>
          </div>
        </div>
      </div>
    </div>
    );
  };

export default observer(Header);