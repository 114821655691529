import React, { useState, useEffect } from 'react';
import {observer} from "mobx-react-lite";
import { Col, Container, Row } from 'react-bootstrap';
import {Link} from 'react-router-dom';
import { useParams } from 'react-router-dom';
import NotFoundPage from './not-found-page';
import API_URL from '../components/http';
const parse = require('html-react-parser');

const StaticPage = () => {

  const [items, setItems] = useState([]);
  const {id} = useParams();
  const getOneNews = async () => {
    const res = await fetch(
      API_URL + `/p/${id}`
    );
    const data = await res.json();
    setItems(data);
  };
  useEffect(() => {
    window.scrollTo(0, 0);
    getOneNews();
  },[setItems]);

  if (items == null) return (
    <NotFoundPage />
  ) 


    return (
      <section className='vr-game' style={{paddingBottom: '250px', paddingTop: '20px'}}>
        <Container>
          <Row md={12} className='d-flex justify-content-between'>
            <Col md={12}>
              <h2 className='vr-header_title'>
                <span>{items.title}</span>
              </h2>
              <hr className="mb-4" />
              <div className="text-blog-i">  {items.content !== undefined ? parse(items.content) : null}</div>
            </Col>
          </Row>
        </Container>
      </section>
    );
};

export default observer(StaticPage);