import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import {observer} from "mobx-react-lite";
import { Col, Container, Row, Button, ButtonGroup } from 'react-bootstrap';
import Carousel from 'react-bootstrap/Carousel';
import Modal from 'react-bootstrap/Modal';
import ReactPlayer from 'react-player';
import NotFoundPage from './not-found-page';
import { Link } from 'react-router-dom';
import API_URL, {IMG_URL} from '../components/http';
import NoImage from '../components/img/noimage.jpg';
import Accordion from 'react-bootstrap/Accordion';

const ProductPage = () => {

  const [items, setItems] = useState([]);
  const {id} = useParams();

  const getOneGame = async () => {
    const res = await fetch(
      API_URL + `/products/product?GUID=${id}`
    );
    const data = await res.json();
    setItems(data);
  };
  useEffect(() => {
    window.scrollTo(0, 0);
    getOneGame();
  },[setItems]);

  if (items == null) return (
    <NotFoundPage />
  ) 

    return (
      <section className='mt-product'>
        <Container>
          <div className='d-flex'>    
            <svg xmlns="http://www.w3.org/2000/svg" style={{marginTop: '2px', marginRight: '10px'}} className="icon icon-tabler icon-tabler-box-seam" width={24} height={24} viewBox="0 0 24 24" stroke-width={2} stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round">
              <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
              <path d="M12 3l8 4.5v9l-8 4.5l-8 -4.5v-9l8 -4.5"></path>
              <path d="M12 12l8 -4.5"></path>
              <path d="M8.2 9.8l7.6 -4.6"></path>
              <path d="M12 12v9"></path>
              <path d="M12 12l-8 -4.5"></path>
            </svg>
            <h4 className='pb-4'>Карточка товара</h4>
          </div>
        </Container>
        <Container>
          <Row md={12} className='d-flex justify-content-between'>
              <Col md={4}>
                <img className='vr-game_logotype' alt={items.title} style={{objectFit: 'cover', width: '100%', borderRadius: '15px', marginBottom: '15px'}} src={NoImage} />
                <ButtonGroup className="w-100" aria-label="First group">
                  <Button variant="outline-primary mb-5">Добавить товар в корзину</Button>
                </ButtonGroup>
              </Col>
              <Col md={8}>
                <h2 className='vr-header_title pb-3'>
                  <span>{items?.name}</span>
                </h2>
                <h5 className='vr-game_cost'>
                    Артикул: <span><strong>00000000000</strong></span>
                </h5>
                <h5 className='vr-game_cost'>
                    Категория товара: <span><strong>{items?.group?.name}</strong></span>
                </h5>
                <h5 className='vr-game_cost'>
                    Стоимость: <span><strong>{items?.price === 0 ? `По запросу` : items?.price + ' ₽'}</strong></span>
                </h5>
                <h5 className='vr-game_cost'>
                    Остаток на складе: <span><strong>{items?.balance === 0 ? `Нет в наличии` : items?.balance + ' шт'}</strong></span>
                </h5>
                <hr className="mb-4" />
                <Accordion defaultActiveKey="1">
                  <Accordion.Item eventKey="0">
                    <Accordion.Header>Характеристики товара</Accordion.Header>
                    <Accordion.Body>
                      <p className='vr-game_cost'>
                          Вес товара: <span><strong>15 килограмм</strong></span>
                      </p>
                      <p className='vr-game_cost'>
                          Габариты товара: <span><strong>150 см x 200 см x 30 см</strong></span>
                      </p>
                      <p className='vr-game_cost'>
                          Материал: <span><strong>Нержавеющая сталь</strong></span>
                      </p>
                      <p className='vr-game_cost'>
                          Цвет: <span><strong>Серебристый</strong></span>
                      </p>
                      <p className='vr-game_cost'>
                          Год изготовления: <span><strong>2019</strong></span>
                      </p>
                      <p className='vr-game_cost'>
                          Гарантия: <span><strong>12 месяцев</strong></span>
                      </p>
                      <p className='vr-game_cost'>
                          Производитель: <span><strong>АО "Тестовый завод"</strong></span>
                      </p>
                    </Accordion.Body>
                  </Accordion.Item>
                  <Accordion.Item eventKey="1">
                    <Accordion.Header>Описание товара</Accordion.Header>
                    <Accordion.Body>
                    <p style={{whiteSpace: 'pre-line'}} className='vr-header_text'>
                  Выхлопная труба - это ключевой элемент системы выпуска двигателя автомобиля, предназначенный для отвода продуктов сгорания топлива, а именно газов, которые образуются в процессе работы двигателя. Она представляет собой металлическую трубу, которая соединяется с двигателем и заканчивается на выходе глушителем или каталитическим нейтрализатором.

Выхлопная система играет важную роль в работе двигателя, так как обеспечивает отвод горячих газов из цилиндров, что позволяет двигателю работать более эффективно и снижает его износ. Также благодаря выхлопной системе снижается уровень шума, создаваемого двигателем при работе.


Основными материалами, используемыми при изготовлении выхлопных труб, являются нержавеющая сталь и алюминизированная сталь. Выбор материала зависит от производителя и модели автомобиля. Выхлопные трубы могут быть цельными или иметь сварные швы, их форма также может быть разной - круглой, овальной или прямоугольной.

С течением времени выхлопные трубы подвергаются коррозии и износу, что может привести к их повреждению и необходимости замены. Если выхлопная труба забита или имеет повреждения, это может негативно сказаться на работе двигателя и вызвать его перегрев, снижение мощности и увеличение расхода топлива. Регулярное обслуживание и замена выхлопной системы помогут поддерживать эффективность работы автомобиля на высоком уровне и избежать дорогостоящего ремонта в будущем.</p>
                    </Accordion.Body>
                  </Accordion.Item>
                </Accordion>
              </Col>
            </Row>
            
        </Container>
      </section>
    );
};

export default observer(ProductPage);