import React, {useState, useEffect} from 'react';
import { Container, Button } from 'react-bootstrap';
import Test from '../components/img/mt-main_sm.png';
import API_URL, { IMG_URL } from './http';
import { Link } from 'react-router-dom';

const NewsComponent = () => {
  const [items, setItems] = useState([]);
  const [pageCount, setpageCount] = useState(0);
  let limit = 3;

  const getNews = async () => {
    const res = await fetch(
      API_URL + `/n?page=1&limit=${limit}`);
    const data = await res.json();
    const total = res.headers.get("x-total-count");
    setpageCount(Math.ceil(total / limit));
    setItems(data);
  };

  useEffect(() => {
    getNews();
  }, [limit]);

  return (
    <Container>
      <div class="px-4 py-5" id="custom-cards">
        <h2 class="pb-2 border-bottom">Свежие новости компании</h2>

        <div class="row row-cols-1 row-cols-lg-3 align-items-stretch g-4 py-5">
          {items.map((item) => {
            return (
            <div class="col" key={item._id}>
              <div class="card card-cover h-100 overflow-hidden text-bg-dark rounded-4 shadow-lg mt_news-bg" style={{backgroundImage: `url('${IMG_URL + item.img}')`}}>
                <div class="d-flex flex-column h-100 pt-5 pb-3 text-shadow-1">
                  <h2 class="pt-5 mt-5 mb-4 ps-4 pe-4 lh-1 fw-bold">{item.title}</h2>
                  <ul class="d-flex ps-4 pe-4 list-unstyled mt-auto justify-content-between">
                    <li class="d-flex align-items-center">
                      <Link to={`/news/${item.news_id}`}><Button variant="outline-light" size="sm">Подробнее</Button></Link>
                    </li>
                    <li class="d-flex align-items-center">
                      <svg xmlns="http://www.w3.org/2000/svg" class="bi me-2" width={24} height={24} viewBox="0 0 24 24" stroke-width={2} stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round">
                        <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                        <path d="M4 5m0 2a2 2 0 0 1 2 -2h12a2 2 0 0 1 2 2v12a2 2 0 0 1 -2 2h-12a2 2 0 0 1 -2 -2z"></path>
                        <path d="M16 3l0 4"></path>
                        <path d="M8 3l0 4"></path>
                        <path d="M4 11l16 0"></path>
                        <path d="M8 15h2v2h-2z"></path>
                      </svg>
                      <small>{item.pubDate}</small>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            )})}
        </div>
        <div class="col-lg-6 mx-auto">
          <hr />
          <div class="d-grid d-sm-flex justify-content-center">
            <Link to="/news"><button type="button" class="btn btn-outline-primary btn-lg px-4">Смотреть все новости</button></Link>
          </div>
        </div>
      </div>
    </Container>
  );
};

export default NewsComponent;