import React, { useState } from "react";
import {BrowserRouter as Router, Routes, Route} from "react-router-dom";
import HomePage from './pages/home-page';
import 'bootstrap/dist/css/bootstrap.min.css';
import './components/style.css';
import 'react-toastify/dist/ReactToastify.css';
import Maket from './components/maket';
import NotFoundPage from "./pages/not-found-page";
import NewsOnepage from "./pages/news-onepage";
import ProductPage from "./pages/product-page";
import SearchPage from "./pages/search-page";
import AltMaket from "./components/alt-maket";
import ContactsPage from "./pages/contacts-page";
import BasketPage from "./pages/basket-page";
import CatalogPage from "./pages/catalog-page";
import StaticPage from "./pages/static-page";
import NewsPage from "./pages/news-page";
import CategoriesPage from "./pages/categories-page";

function App() {
  return (
    <Router>
    <Routes>
      <Route element={<Maket />}>
        <Route path="/" element={<HomePage />} />
        <Route path="/products" element={<CatalogPage />} />
        <Route path="/news" element={<NewsPage />} />
        <Route path="/news/:id" element={<NewsOnepage />} />
        <Route path="/categories/:id" element={<CategoriesPage />} />
        <Route path="/pages/:id" element={<StaticPage />} />
        <Route path="/products/:id" element={<ProductPage />} />
        <Route path="/cart" element={<BasketPage />} />
        <Route path="/help" element={<ProductPage />} />
        <Route path="/contacts" element={<ContactsPage />} />
        <Route path="*" element={<NotFoundPage />} />
      </Route>
      <Route element={<AltMaket />}>
        <Route path="/search/:search" element={<SearchPage />} />
      </Route>
    </Routes>
  </Router>
  )
}

export default App;