import React from 'react';
import PropTypes from 'prop-types';
import { Container } from 'react-bootstrap';

const Features = () => {
  return (
    <Container>
      <div className=" px-4 py-5">
        <h3 className="pb-2 border-bottom">Почему компании работают с нами?</h3>

        <div className="row row-cols-1 row-cols-md-2 align-items-md-center g-5 py-5">
          <div className="col d-flex flex-column align-items-start gap-2">
            <h4 className="fw-bold text-body-emphasis">Начните сотрудничество с МоторТрейд!</h4>
            <p className="text-body-secondary">
              Компания работает на рынке специализированного транспорта и автозапчастей с 2003 года. Показатель ее авторитета — многолетнее непрерывное сотрудничество с такими предприятиями, как ПАО «Сургутнефтегаз», ПАО «Сибур-Тюмень», ПАО «Славнефть-Мегионнефтегаз», компания «Шлюмберже Лоджелко Инк», ПАО «Спецгазавтотранс» и др.
            </p>
            <p className="text-body-secondary">
              Благодаря ответственному выполнению взятых на себя обязательств компании удается удерживать высокую планку по надежности и компетентности, а предлагаемые ею выгодные условия сотрудничества являются важным фактором для привлечения новых Клиентов.
            </p>
          </div>

          <div className="col">
            <div className="row row-cols-1 row-cols-sm-2 g-4">
              <div className="col d-flex flex-column gap-2">
                <div className="mt_feature-icon d-inline-flex align-items-center justify-content-center text-bg-primary bg-gradient fs-4 rounded-3">
                  <svg xmlns="http://www.w3.org/2000/svg" className="bi" width={24} height={24} viewBox="0 0 24 24" stroke-width={2} stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round">
                    <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                    <path d="M5 21v-16a2 2 0 0 1 2 -2h10a2 2 0 0 1 2 2v16l-3 -2l-2 2l-2 -2l-2 2l-2 -2l-3 2m4 -14h6m-6 4h6m-2 4h2"></path>
                  </svg>
                </div>
                <h5 className="fw-semibold mb-0 text-body-emphasis">Низкие цены</h5>
                <p className="text-body-secondary">Работая напрямую с крупнейшими поставщиками от самих заводов изготовителей ООО «МоторТрейд» предлагает запчасти для автобусов по ценам ниже рыночных.</p>
              </div>

              <div className="col d-flex flex-column gap-2">
                <div className="mt_feature-icon d-inline-flex align-items-center justify-content-center text-bg-primary bg-gradient fs-4 rounded-3">
                  <svg xmlns="http://www.w3.org/2000/svg" className="bi" width={24} height={24} viewBox="0 0 24 24" stroke-width={2} stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round">
                    <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                    <path d="M7 16.5l-5 -3l5 -3l5 3v5.5l-5 3z"></path>
                    <path d="M2 13.5v5.5l5 3"></path>
                    <path d="M7 16.545l5 -3.03"></path>
                    <path d="M17 16.5l-5 -3l5 -3l5 3v5.5l-5 3z"></path>
                    <path d="M12 19l5 3"></path>
                    <path d="M17 16.5l5 -3"></path>
                    <path d="M12 13.5v-5.5l-5 -3l5 -3l5 3v5.5"></path>
                    <path d="M7 5.03v5.455"></path>
                    <path d="M12 8l5 -3"></path>
                  </svg>
                </div>
                <h5 className="fw-semibold mb-0 text-body-emphasis">Всегда в наличии</h5>
                <p className="text-body-secondary">Практически все из нашего каталога имеется в наличии, а если чего то и нет или Вам нужны редкие запчасти - то мы закажем их в самые короткие сроки.</p>
              </div>

              <div className="col d-flex flex-column gap-2">
                <div className="mt_feature-icon d-inline-flex align-items-center justify-content-center text-bg-primary bg-gradient fs-4 rounded-3">
                  <svg xmlns="http://www.w3.org/2000/svg" className="bi" width={24} height={24} viewBox="0 0 24 24" stroke-width={2} stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round">
                    <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                    <path d="M20.972 11.291a9 9 0 1 0 -8.322 9.686"></path>
                    <path d="M3.6 9h16.8"></path>
                    <path d="M3.6 15h8.9"></path>
                    <path d="M11.5 3a17 17 0 0 0 0 18"></path>
                    <path d="M12.5 3a16.986 16.986 0 0 1 2.578 9.018"></path>
                    <path d="M21.121 20.121a3 3 0 1 0 -4.242 0c.418 .419 1.125 1.045 2.121 1.879c1.051 -.89 1.759 -1.516 2.121 -1.879z"></path>
                    <path d="M19 18v.01"></path>
                  </svg>
                </div>
                <h5 className="fw-semibold mb-0 text-body-emphasis">Доставка по всей России</h5>
                <p className="text-body-secondary">Мы готовы отправить необходимые Вам детали в любой уголок России любой удобной для Вас транспортной компанией на привлекательных условиях.</p>
              </div>

              <div className="col d-flex flex-column gap-2">
                <div className="mt_feature-icon d-inline-flex align-items-center justify-content-center text-bg-primary bg-gradient fs-4 rounded-3">
                  <svg xmlns="http://www.w3.org/2000/svg" className="bi" width={24} height={24} viewBox="0 0 24 24" stroke-width={2} stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round">
                    <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                    <path d="M15 15m-3 0a3 3 0 1 0 6 0a3 3 0 1 0 -6 0"></path>
                    <path d="M13 17.5v4.5l2 -1.5l2 1.5v-4.5"></path>
                    <path d="M10 19h-5a2 2 0 0 1 -2 -2v-10c0 -1.1 .9 -2 2 -2h14a2 2 0 0 1 2 2v10a2 2 0 0 1 -1 1.73"></path>
                    <path d="M6 9l12 0"></path>
                    <path d="M6 12l3 0"></path>
                    <path d="M6 15l2 0"></path>
                  </svg>
                </div>
                <h5 className="fw-semibold mb-0 text-body-emphasis">Гарантия полгода</h5>
                <p className="text-body-secondary">На все продаваемые ними запчасти мы даем полугодовую гарантию. Если в течении этого времени деталь выйдет из строя - мы без проблем заменим ее.</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Container>
  );
};

export default Features;