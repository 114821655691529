import React, {useState, useEffect} from 'react';
import { Container, Row } from 'react-bootstrap';
import API_URL from './http';
import { Link } from 'react-router-dom';

const CategoriesComponent = () => {
  const [items, setItems] = useState([]);

  const getCategories = async () => {
    const res = await fetch(
      API_URL + `/products/categories`);
    const data = await res.json();
    setItems(data);
  };

  useEffect(() => {
    getCategories();
  }, [items]);
  //http://localhost:5000/api/products/categories
  return (
    <div>
        <Container>
          <Row className="d-flex justify-content-center">
          {items.map((item) => {
            return (
            <Link key={item.GUID} class="col-md-2 col-sm-6 mt-2 mt_btn-categories" to={`/categories/${item.GUID}`}>
              <div class="h-100 p-4 text-bg-primary rounded-5">
                {
                  item.name === 'Кароса, Ивеко' ?
                  <svg xmlns="http://www.w3.org/2000/svg" style={{display: 'block', marginLeft: 'auto', marginRight: 'auto', marginTop: '15px', marginBottom: '15px'}} className="icon icon-tabler icon-tabler-truck-delivery" width={64} height={64} viewBox="0 0 24 24" stroke-width={1.2} stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round">
                    <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                    <path d="M7 17m-2 0a2 2 0 1 0 4 0a2 2 0 1 0 -4 0"></path>
                    <path d="M17 17m-2 0a2 2 0 1 0 4 0a2 2 0 1 0 -4 0"></path>
                    <path d="M5 17h-2v-4m-1 -8h11v12m-4 0h6m4 0h2v-6h-8m0 -5h5l3 5"></path>
                    <path d="M3 9l4 0"></path>
                  </svg>
                  : item.name === 'Разное' ?
                  <svg xmlns="http://www.w3.org/2000/svg" style={{display: 'block', marginLeft: 'auto', marginRight: 'auto', marginTop: '15px', marginBottom: '15px'}} className="icon icon-tabler icon-tabler-box-seam" width={64} height={64} viewBox="0 0 24 24" stroke-width={1.2} stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round">
                    <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                    <path d="M12 3l8 4.5v9l-8 4.5l-8 -4.5v-9l8 -4.5"></path>
                    <path d="M12 12l8 -4.5"></path>
                    <path d="M8.2 9.8l7.6 -4.6"></path>
                    <path d="M12 12v9"></path>
                    <path d="M12 12l-8 -4.5"></path>
                  </svg>
                  : item.name === 'Услуги' ?
                  <svg xmlns="http://www.w3.org/2000/svg" style={{display: 'block', marginLeft: 'auto', marginRight: 'auto', marginTop: '15px', marginBottom: '15px'}} className="icon icon-tabler icon-tabler-settings" width={64} height={64} viewBox="0 0 24 24" stroke-width={1.2} stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round">
                    <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                    <path d="M10.325 4.317c.426 -1.756 2.924 -1.756 3.35 0a1.724 1.724 0 0 0 2.573 1.066c1.543 -.94 3.31 .826 2.37 2.37a1.724 1.724 0 0 0 1.065 2.572c1.756 .426 1.756 2.924 0 3.35a1.724 1.724 0 0 0 -1.066 2.573c.94 1.543 -.826 3.31 -2.37 2.37a1.724 1.724 0 0 0 -2.572 1.065c-.426 1.756 -2.924 1.756 -3.35 0a1.724 1.724 0 0 0 -2.573 -1.066c-1.543 .94 -3.31 -.826 -2.37 -2.37a1.724 1.724 0 0 0 -1.065 -2.572c-1.756 -.426 -1.756 -2.924 0 -3.35a1.724 1.724 0 0 0 1.066 -2.573c-.94 -1.543 .826 -3.31 2.37 -2.37c1 .608 2.296 .07 2.572 -1.065z"></path>
                    <path d="M9 12a3 3 0 1 0 6 0a3 3 0 0 0 -6 0"></path>
                  </svg>
                  :
                  <svg xmlns="http://www.w3.org/2000/svg" style={{display: 'block', marginLeft: 'auto', marginRight: 'auto', marginTop: '15px', marginBottom: '15px'}} className="icon icon-tabler icon-tabler-box-seam" width={64} height={64} viewBox="0 0 24 24" stroke-width={1.2} stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round">
                    <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                    <path d="M12 3l8 4.5v9l-8 4.5l-8 -4.5v-9l8 -4.5"></path>
                    <path d="M12 12l8 -4.5"></path>
                    <path d="M8.2 9.8l7.6 -4.6"></path>
                    <path d="M12 12v9"></path>
                    <path d="M12 12l-8 -4.5"></path>
                  </svg>
                }
                <h5 className="text-center">{item.name}</h5>
              </div>
            </Link>
            )})}
          </Row>
        </Container>
    </div>
  );
};

export default CategoriesComponent;