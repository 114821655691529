import React, {useEffect} from 'react';
import {observer} from "mobx-react-lite";
import { Col, Container, Row } from 'react-bootstrap';
import { Link } from 'react-router-dom';

const NotFoundPage = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  document.title = 'Страница не найдена! | Интернет-магазин автозапчастей - Мотор Трейд';
    return (
      <section className="vr-main">
      <Container>
        <div className="d-flex align-items-center py-4">
          <main className="form-signin w-50 m-auto">
            <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-exclamation-circle mt_cart-null_svg" width={64} height={64} viewBox="0 0 24 24" stroke-width={2} stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round">
              <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
              <path d="M12 12m-9 0a9 9 0 1 0 18 0a9 9 0 1 0 -18 0"></path>
              <path d="M12 9v4"></path>
              <path d="M12 16v.01"></path>
            </svg>
            <h3 className="h3 mb-3 fw-normal text-center">Ошибка!</h3>
            <p className="text-body-secondary text-center mt_cart-null">Такой страницы нет на нашем сайте..</p>
          </main>
        </div>
      </Container>
    </section>
    );
};

export default observer(NotFoundPage);