import React, {useState, useEffect} from 'react';
import {observer} from "mobx-react-lite";
import { Col, Container, Row } from 'react-bootstrap';
import SocialImg from '../components/img/social.png';
import { Link } from 'react-router-dom';
import API_URL from '../components/http';
import Button from 'react-bootstrap/Button';
import ButtonGroup from 'react-bootstrap/ButtonGroup';
import FloatingLabel from 'react-bootstrap/FloatingLabel';
import Form from 'react-bootstrap/Form';
import errorNotify, { successNotify } from '../components/notify';


const BasketPage = () => {
  const [cart, setCart] = useState([]);
  const [btnStatus, setBtnStatus] = useState(false);
  const [name, setName] = useState('');
  const [phone, setPhone] = useState('');
  const [email, setEmail] = useState('');

  async function plusProducts(products_id) {
    const myNextList = [...cart];
    const candidate = myNextList.find(
      a => a.product_id === products_id
    );
    candidate.count = candidate.count + 1;
    setCart(myNextList);
    localStorage.removeItem("cart");
    localStorage.setItem("cart", JSON.stringify(cart));
  }

  async function minusProducts(products_id) {
    const myNextList = [...cart];
    const candidate = myNextList.find(
      a => a.product_id === products_id
    );
    if (candidate.count == 1) return errorNotify('Нельзя указать меньше 1 единицы!');
    candidate.count = candidate.count - 1;
    setCart(myNextList);
    localStorage.removeItem("cart");
    localStorage.setItem("cart", JSON.stringify(cart));
  }

  async function deleteProducts(products_id) {
    setCart(cart.filter(a => a.product_id !== products_id));
    localStorage.removeItem("cart");
    localStorage.setItem("cart", JSON.stringify(cart.filter(a => a.product_id !== products_id)));
  }

  const toggleBtnStatus = () => {
    setBtnStatus(true);
    setTimeout(() => {
      setBtnStatus(false);
    }, 5000);
  }

  const toggleBtnDelete = () => {
    localStorage.removeItem("cart");
    setCart([]);
  }

  useEffect(() => {
    window.scrollTo(0, 0);
    if (localStorage.getItem("cart")) {
      setCart(JSON.parse(localStorage.getItem("cart")));
      console.log(JSON.parse(localStorage.getItem("cart")));
    }
  }, [setCart]);
  document.title = 'Корзина | Интернет-магазин автозапчастей - Мотор Трейд'
    return (
      <section className="mt-contacts">
        <Container>
          <div className='d-flex'>    
            <svg xmlns="http://www.w3.org/2000/svg" style={{marginTop: '2px', marginRight: '10px'}} className="icon icon-tabler icon-tabler-basket" width={24} height={24} viewBox="0 0 24 24" strokeWidth={2} stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round">
              <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
              <path d="M10 14a2 2 0 1 0 4 0a2 2 0 0 0 -4 0"></path>
              <path d="M5.001 8h13.999a2 2 0 0 1 1.977 2.304l-1.255 7.152a3 3 0 0 1 -2.966 2.544h-9.512a3 3 0 0 1 -2.965 -2.544l-1.255 -7.152a2 2 0 0 1 1.977 -2.304z"></path>
              <path d="M17 10l-2 -6"></path>
              <path d="M7 10l2 -6"></path>
            </svg>
            <h4>Корзина</h4>
          </div>
        </Container>

        <Container style={{marginTop: '20px'}}>
        {
          cart.length !== 0 ?
          <div className="row align-items-md-stretch">
            <div className="col-md-6">
              <div className="mb-3 p-3 bg-body rounded shadow-sm">
                <h6 className="border-bottom pb-2 mb-0">Список товаров</h6>
                {
                cart.map((citem) => {
                  return (
                  <div className="d-flex text-muted pt-3" key={citem.product_id}>
                    <svg className="bd-placeholder-img flex-shrink-0 me-2 rounded" width="32" height="32" xmlns="http://www.w3.org/2000/svg" role="img" aria-label="Placeholder: 32x32" preserveAspectRatio="xMidYMid slice" focusable="false"><title>Placeholder</title><rect width="100%" height="100%" fill="#007bff"></rect><text x="50%" y="50%" fill="#007bff" dy=".3em"></text></svg>
                    <div className="pb-3 mb-0 small lh-sm border-bottom w-100">
                      <div className="d-flex justify-content-between">
                        <strong className="text-gray-dark">{citem.name}</strong>
                        <ButtonGroup size="sm" aria-label="Basic example">
                          <Button onClick={() => minusProducts(citem.product_id)} variant="outline-primary">
                            <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-minus" width={20} height={20} viewBox="0 0 24 24" strokeWidth={2} stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round">
                              <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                              <path d="M5 12l14 0"></path>
                            </svg>
                          </Button>
                          <Button onClick={() => plusProducts(citem.product_id)} variant="outline-primary">
                            <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-plus" width={20} height={20} viewBox="0 0 24 24" strokeWidth={2} stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round">
                              <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                              <path d="M12 5l0 14"></path>
                              <path d="M5 12l14 0"></path>
                            </svg>
                          </Button>
                          <Button onClick={() => deleteProducts(citem.product_id)} variant="outline-primary">
                            <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-trash" width={20} height={20} viewBox="0 0 24 24" strokeWidth={2} stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round">
                              <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                              <path d="M4 7l16 0"></path>
                              <path d="M10 11l0 6"></path>
                              <path d="M14 11l0 6"></path>
                              <path d="M5 7l1 12a2 2 0 0 0 2 2h8a2 2 0 0 0 2 -2l1 -12"></path>
                              <path d="M9 7v-3a1 1 0 0 1 1 -1h4a1 1 0 0 1 1 1v3"></path>
                            </svg>
                          </Button>
                        </ButtonGroup>
                      </div>
                      <span className="d-block">{citem.count} шт</span>
                    </div>
                  </div>
                  )
                })
              }
                <small className="d-block text-end mt-3">
                  {
                    btnStatus === false ?
                    <button type="button" onClick={() => toggleBtnStatus()} className="btn btn-light text-dark me-2 mt-btn_contacts">
                      <span>Очистить корзину</span>
                    </button>
                    :
                    <button type="button" onClick={() => toggleBtnDelete()} className="btn btn-light text-dark me-2 mt-btn_contacts">
                      <span>Вы уверены?</span>
                    </button>
                  }
                </small>
              </div>

            </div>
            <div className="col-md-6">
              <div className="h-100 p-5 text-white bg-primary rounded-3">
                <h4>Сформировать заказ</h4>
                <p>Заполните контактные данные и нажмите "Сформировать"</p>
                <hr />
                <FloatingLabel
                  controlId="floatingInput"
                  label="Как вас зовут?"
                  className="mb-3 text-dark"
                >
                  <Form.Control type="text" className="text-dark" placeholder="Ivanov Ivan" />
                </FloatingLabel>
                <FloatingLabel
                  controlId="floatingInput"
                  label="Номер телефона"
                  className="mb-3 text-dark"
                >
                  <Form.Control type="text" className="text-dark" placeholder="+70000000000" />
                </FloatingLabel>
                <FloatingLabel
                  controlId="floatingInput"
                  label="Адрес электронной почты"
                  className="mb-3 text-dark"
                >
                  <Form.Control type="email" className="text-dark" placeholder="name@example.com" />
                </FloatingLabel>
                <p><small>Нажимая "Сформировать" вы соглашаетесь с условиями Политики конфиденциальности, порядком обработки персональных данных и Пользовательским соглашением сайта.</small></p>
                <button className="btn btn-outline-light" type="button">Сформировать</button>
              </div>
            </div>
          </div>
          :
          <div className="d-flex align-items-center py-4">
          <main className="form-signin w-50 m-auto">

            <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-basket-off text-center mt_cart-null_svg" width={64} height={64} viewBox="0 0 24 24" strokeWidth={2} stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round">
              <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
              <path d="M17 10l-2 -6"></path>
              <path d="M7 10l.75 -2.252m1.001 -3.002l.249 -.746"></path>
              <path d="M12 8h7a2 2 0 0 1 1.977 2.304c-.442 2.516 -.756 4.438 -.977 5.696m-1.01 3.003a2.997 2.997 0 0 1 -2.234 .997h-9.512a3 3 0 0 1 -2.965 -2.544l-1.255 -7.152a2 2 0 0 1 1.977 -2.304h2.999"></path>
              <path d="M12 12a2 2 0 1 0 2 2"></path>
              <path d="M3 3l18 18"></path>
            </svg>
            <h3 className="h3 mb-3 fw-normal text-center">Ваша корзина пуста</h3>
            <p className="text-body-secondary text-center mt_cart-null">Добавьте товары в корзину и они появятся здесь</p>
          </main>
          </div>
        }
        </Container>
      </section>
    );
};

export default observer(BasketPage);