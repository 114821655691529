import React, { useState, useEffect } from 'react';
import {observer} from "mobx-react-lite";
import { Col, Container, Row } from 'react-bootstrap';
import {Link} from 'react-router-dom';
import { useParams } from 'react-router-dom';
import NotFoundPage from './not-found-page';
import API_URL, {IMG_URL} from '../components/http';
import NoImage from '../components/img/no-image.jpg';

const NewsOnePage = () => {

  const [items, setItems] = useState([]);
  const {id} = useParams();
  const getOneNews = async () => {
    const res = await fetch(
      API_URL + `/n/${id}`
    );
    const data = await res.json();
    setItems(data);
  };
  useEffect(() => {
    window.scrollTo(0, 0);
    getOneNews();
  },[setItems]);

  if (items == null) return (
    <NotFoundPage />
  ) 


    return (
      <section className='vr-game' style={{paddingBottom: '250px', paddingTop: '20px'}}>
        <Container>
        <Link to="/news"><button type="button" style={{marginBottom: '20px', marginLeft: 0}} className="btn btn-outline-primary"><i class='bx bx-arrow-back bx-fw' style={{paddingTop: '1px'}}></i> Вернуться к списку новостей</button></Link>
          <Row md={12} className='d-flex justify-content-between'>
            <Col md={4}>
              <img className='vr-game_logotype' alt={items.title} style={{objectFit: 'cover', width: '100%', borderRadius: '15px', marginBottom: '30px'}} src={IMG_URL + '/' + items.img} />
            </Col>
            <Col md={8}>
              <h2 className='vr-header_title'>
                <span>{items.title}</span>
              </h2>
              <h5 className='vr-game_cost'>
                  ДАТА ПУБЛИКАЦИИ: <span>{items.pubDate}</span>
              </h5>
              <hr className="mb-4" />
              <p style={{whiteSpace: 'pre-line'}} className='vr-header_text'>
              {items.fullNews}
              </p>
            </Col>
          </Row>
        </Container>
      </section>
    );
};

export default observer(NewsOnePage);