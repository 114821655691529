import React, {useEffect} from 'react';
import {observer} from "mobx-react-lite";
import { Col, Container, Row } from 'react-bootstrap';
import SocialImg from '../components/img/social.png';
import Carousel from 'react-bootstrap/Carousel';
import { Link } from 'react-router-dom';
import API_URL, {IMG_URL} from '../components/http';
import Features from '../components/features';
import CategoriesComponent from '../components/categories';
import NewsComponent from '../components/news';


const HomePage = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  })
  document.title = 'Интернет-магазин автозапчастей - Мотор Трейд'


    return (
      <>
      <Container>
        <section className="mt-main mb-2">
            <Row className="d-flex justify-content-between">
              <Col md={4} lg={6} xl={4}>
                <h4 className="mt-header-main">АВТОМОБИЛЬНЫЕ ЗАПЧАСТИ ДЛЯ ГРУЗОВОГО ТРАНСПОРТА</h4>
                <p className="mt-text-main">ООО «МоторТрейд» — известная в Сургуте и ХМАО компания, которая поставляет оригинальные запчасти для автобусов Karosa, CROSSWAY, Scania, MAN, Mercedes, Iveco, а также для канадской специализированной гусеничной техники Foremost Husky 8.</p>
                <p className="mt-text-main_alt">Начав сотрудничество с ООО «МоторТрейд», вы приобретете партнера, который не подведет со сроками и условиями!</p>
              </Col>
              <Col md={8} lg={5} xl={7} className="mt-main_hero">

              </Col>
            </Row>
        </section>
      </Container>
      {/* <Features /> */}
      <CategoriesComponent />
      <Features />
      <NewsComponent />
      </>
    );
};

export default observer(HomePage);