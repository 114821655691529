import React, {useState, useEffect} from 'react';
import { observer } from "mobx-react-lite";
import AltHeader from './maket/alt-header';
import { Navbar } from 'react-bootstrap';
import Footer from './maket/footer';
import { Outlet, useNavigate, useLocation } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import API_URL, {IMG_URL} from './http';
import {Badge} from 'react-bootstrap';
import {NavLink, Link} from 'react-router-dom';
const AltMaket = () => {
  const location = useLocation();
  const navigate = useNavigate();
  function menu() {
    const root = document.documentElement;
    const items = Array.from(document.querySelectorAll("#sm-menu"));
    // items.forEach((item, index) => 
    // {
    //   item.removeAttribute('data-active');
    // });
    if(location.pathname === '/') {
      root.style.setProperty('--active', 0);
      items.forEach((item, index) => 
      {
        if(index === 0) item.setAttribute("data-active", true);
        if(index !== 0) item.removeAttribute('data-active');
        item.style.setProperty('--i', index);
        
          item.addEventListener("click", e => 
        {
          root.style.setProperty('--active', index);
          root.querySelectorAll("[data-active]").forEach(el => el.removeAttribute("data-active"));
            item.setAttribute("data-active", true);
          });
      });
    } else if (location.pathname === '/products') {
      root.style.setProperty('--active', 1);
      items.forEach((item, index) => 
      {
        if(index === 1) item.setAttribute("data-active", true);
        if(index !== 1) item.removeAttribute('data-active');
        item.style.setProperty('--i', index);
        
          item.addEventListener("click", e => 
        {
          root.style.setProperty('--active', index);
          root.querySelectorAll("[data-active]").forEach(el => el.removeAttribute("data-active"));
            item.setAttribute("data-active", true);
          });
      });
    } else if (location.pathname === '/cart') {
      root.style.setProperty('--active', 2);
      items.forEach((item, index) => 
      {
        if(index === 2) item.setAttribute("data-active", true);
        if(index !== 2) item.removeAttribute('data-active');
        item.style.setProperty('--i', index);
        
          item.addEventListener("click", e => 
        {
          root.style.setProperty('--active', index);
          root.querySelectorAll("[data-active]").forEach(el => el.removeAttribute("data-active"));
            item.setAttribute("data-active", true);
          });
      });
    } else if (location.pathname === '/pages/1') {
      root.style.setProperty('--active', 3);
      items.forEach((item, index) => 
      {
        if(index === 3) item.setAttribute("data-active", true);
        if(index !== 3) item.removeAttribute('data-active');
        item.style.setProperty('--i', index);
        
          item.addEventListener("click", e => 
        {
          root.style.setProperty('--active', index);
          root.querySelectorAll("[data-active]").forEach(el => el.removeAttribute("data-active"));
            item.setAttribute("data-active", true);
          });
      });
    } else {
      root.style.setProperty('--active', 0);
      items.forEach((item, index) => 
      {
        if(index === 0) item.setAttribute("data-active", true);
        if(index !== 0) item.removeAttribute('data-active');
        item.style.setProperty('--i', index);
        
          item.addEventListener("click", e => 
        {
          root.style.setProperty('--active', index);
          root.querySelectorAll("[data-active]").forEach(el => el.removeAttribute("data-active"));
            item.setAttribute("data-active", true);
          });
      });
    }
  }
  useEffect(() => {
    menu();
  }, []);
  menu();

  return (
    <div className="mt-wrapper">
      <ToastContainer />
      <AltHeader />
      <Outlet />
      <div className="mt-sm_menu">
        <ul>
          <div className="bar"></div>
          <li onClick={() => navigate('/')} id="sm-menu"><div className="icon">
            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" strokeWidth="2" stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round">
              <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
              <path d="M5 12l-2 0l9 -9l9 9l-2 0"></path>
              <path d="M5 12v7a2 2 0 0 0 2 2h10a2 2 0 0 0 2 -2v-7"></path>
              <path d="M9 21v-6a2 2 0 0 1 2 -2h2a2 2 0 0 1 2 2v6"></path>
            </svg>
            </div><div className="text">Главная</div></li>
          {/* <li onClick={() => navigate('/products')} id="sm-menu"><div className="icon">
            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" strokeWidth="2" stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round">
              <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
              <path d="M3 21l18 0"></path>
              <path d="M3 7v1a3 3 0 0 0 6 0v-1m0 1a3 3 0 0 0 6 0v-1m0 1a3 3 0 0 0 6 0v-1h-18l2 -4h14l2 4"></path>
              <path d="M5 21l0 -10.15"></path>
              <path d="M19 21l0 -10.15"></path>
              <path d="M9 21v-4a2 2 0 0 1 2 -2h2a2 2 0 0 1 2 2v4"></path>
            </svg>
            </div><div className="text">Каталог</div></li> */}
          <li onClick={() => navigate('/cart')} id="sm-menu"><div className="icon">
            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" strokeWidth="2" stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round">
              <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
              <path d="M10 14a2 2 0 1 0 4 0a2 2 0 0 0 -4 0"></path>
              <path d="M5.001 8h13.999a2 2 0 0 1 1.977 2.304l-1.255 7.152a3 3 0 0 1 -2.966 2.544h-9.512a3 3 0 0 1 -2.965 -2.544l-1.255 -7.152a2 2 0 0 1 1.977 -2.304z"></path>
              <path d="M17 10l-2 -6"></path>
              <path d="M7 10l2 -6"></path>
            </svg>
          </div><div className="text">Корзина</div></li>
          <li onClick={() => navigate('/pages/1')} id="sm-menu"><div className="icon">
            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" strokeWidth="2" stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round">
              <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
              <path d="M10.24 3.957l-8.422 14.06a1.989 1.989 0 0 0 1.7 2.983h16.845a1.989 1.989 0 0 0 1.7 -2.983l-8.423 -14.06a1.989 1.989 0 0 0 -3.4 0z"></path>
              <path d="M12 17v.01"></path>
              <path d="M12 14a2 2 0 0 0 .914 -3.782a1.98 1.98 0 0 0 -2.414 .483"></path>
            </svg>
          </div><div className="text">Помощь</div></li>
        </ul>
      </div>
      <Footer />
    </div>
  );
};

export default observer(AltMaket);