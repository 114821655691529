import React, { useState, useEffect } from 'react';
import { Col, Container, Row, Card, Button, ButtonGroup } from 'react-bootstrap';
import ReactPaginate from 'react-paginate';
import {Link} from 'react-router-dom';
import API_URL, {IMG_URL} from '../components/http';

const NewsPage = () => {

  const [items, setItems] = useState([]);
  const [pageCount, setpageCount] = useState(0);
  let limit = 6;
  document.title = 'Все новости | Интернет-магазин автозапчастей - Мотор Трейд'


  const getNews = async () => {
    const res = await fetch(
      API_URL + `/n?page=1&limit=${limit}`);
    const data = await res.json();
    const total = res.headers.get("x-total-count");
    setpageCount(Math.ceil(total / limit));
    setItems(data);
  };

  const fetchNews = async (currentPage) => {
    const res = await fetch(
      API_URL + `/n?page=${currentPage}&limit=${limit}`);
    const data = await res.json();
    return data;
  };

  const handlePageClick = async (data) => {
    let currentPage = data.selected + 1;
    const commentsFormServer = await fetchNews(currentPage);
    setItems(commentsFormServer);
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    getNews();
  }, [limit]);

    return (
      <section className="vr-main" style={{height: 'auto', marginTop: 0}}>
          <Container>
          <Row md={12} className='d-flex justify-content-center'>
              <div className='d-flex' style={{marginBottom: '20px'}}>    
                <svg xmlns="http://www.w3.org/2000/svg" style={{marginTop: '2px', marginRight: '10px'}}  className="icon icon-tabler icon-tabler-news" width={24} height={24} viewBox="0 0 24 24" stroke-width={2} stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round">
                  <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                  <path d="M16 6h3a1 1 0 0 1 1 1v11a2 2 0 0 1 -4 0v-13a1 1 0 0 0 -1 -1h-10a1 1 0 0 0 -1 1v12a3 3 0 0 0 3 3h11"></path>
                  <path d="M8 8l4 0"></path>
                  <path d="M8 12l4 0"></path>
                  <path d="M8 16l4 0"></path>
                </svg>
                <h4>Все новости</h4>
              </div>
            </Row>
            <Row md={12} className='d-flex justify-content-center gap-5 vr-games_cards'>
            {items.map((item) => {
              return (
                <Card key={item._id} style={{ width: '18rem' }}>
                <Card.Img variant="top" style={{objectFit: 'cover', width: '100%', marginTop: '13px'}} src={IMG_URL + item.img} />
                <Card.Body className="d-flex flex-wrap">
                  <Card.Title>{item.title}</Card.Title>
                  <div className="align-self-center">
                    <hr />
                    <Card.Text>
                    <strong>{item.pubDate}</strong>
                    </Card.Text>
                    <ButtonGroup aria-label="Basic example">
                      <Link to={`/news/${item.news_id}`} className="btn btn-primary">
                          Посмотреть
                      </Link>
                    </ButtonGroup>
                </div>
                </Card.Body>
              </Card>
              )
            })}
            </Row>
            <Row md={12} className='d-flex justify-content-center vr-games-cards_footer mt-4'>
              <Col md={4} className='d-flex justify-content-center'>
                <ReactPaginate
                  marginPagesDisplayed={2}
                  pageRangeDisplayed={3}
                  previousLabel={"Назад"}
                  nextLabel={"Вперед"}
                  pageCount={pageCount}
                  onPageChange={handlePageClick}
                  containerClassName="pagination"
                  breakClassName="page-item"
                  breakLabel={<div className="page-link">...</div>}
                  pageClassName="page-item"
                  previousClassName="page-item"
                  nextClassName="page-item"
                  pageLinkClassName="page-link"
                  previousLinkClassName="page-link"
                  nextLinkClassName="page-link"
                  activeClassName="active paginate-btn-active"
                />
              </Col>
            </Row>
          </Container>
      </section>
    );
};

export default NewsPage;