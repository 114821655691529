import React, { useState, useEffect } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import ReactPaginate from 'react-paginate';
import {Link} from 'react-router-dom';
import API_URL, {IMG_URL} from '../components/http';
import { useParams } from 'react-router-dom';
import NoImage from '../components/img/noimage.jpg';
import Button from 'react-bootstrap/Button';
import Card from 'react-bootstrap/Card';
import Placeholder from 'react-bootstrap/Placeholder';
import ListGroup from 'react-bootstrap/ListGroup';
import Spinner from 'react-bootstrap/Spinner';
import Form from 'react-bootstrap/Form';
import ButtonGroup from 'react-bootstrap/ButtonGroup';
import errorNotify, { successNotify } from '../components/notify';

const CategoriesPage = () => {
  const [categories, setCategories] = useState([]);
  const [loadingCategories, setLoadingCategories] = useState(true);

  const [selectedCategory, setSelectedCategory] = useState(useParams()?.id);
  const [selectedStock, setSelectedStock] = useState('');

  const [loadingSearch, setLoadingSearch] = useState(true);
  const [loadingPaginate, setLoadingPaginate] = useState(false);
  const [items, setItems] = useState([]);
  const [pageCount, setpageCount] = useState(0);
  const [itemSearch, setItemSearch] = useState('');
  const [cart, setCart] = useState([]);

  const [updateStatsCart, setUpdateStatsCart] = useState(0);

  useEffect(() => {
    window.scrollTo(0, 0);
    if (localStorage.getItem("cart")) {
      setCart(JSON.parse(localStorage.getItem("cart")));
    }
  }, [setCart]);
  let limit = 9;
  document.title = 'Каталог | Интернет-магазин автозапчастей - Мотор Трейд'

  React.useEffect(() => {
    if (updateStatsCart === 1) {
        localStorage.removeItem("cart");
        localStorage.setItem("cart", JSON.stringify(cart));
        setUpdateStatsCart(0);
    } else {
      
    }
  }, [updateStatsCart]);

  async function addCart(product_id, product_name, product_price) {
    if(localStorage.getItem("cart")) {
      const findCandidate = cart.some((item, index, arr) => item.product_id === product_id)
      if (findCandidate) return errorNotify('Выбранный товар уже в корзине!');
      setCart(oldArray => [...oldArray, {
        'product_id': product_id,
        'name': product_name,
        'price': product_price,
        'count': 1
      }]);
      successNotify('Добавлено в корзину!');
      setUpdateStatsCart(1);
    } else {
      setCart(oldArray => [...oldArray, {
        'product_id': product_id,
        'name': product_name,
        'price': product_price,
        'count': 1
      }]);
      successNotify('Добавлено в корзину!');
      setUpdateStatsCart(1);
    }
  }

  async function getProducts() {
    if (selectedCategory !== '' && selectedStock !== '') {
      const res = await fetch(
      API_URL + `/products/products?page=1&limit=${limit}&groupGUID=${selectedCategory}&showInStock=${selectedStock}`);
      const data = await res.json();
      const total = res.headers.get("x-total-count");
      setpageCount(Math.ceil(total / limit));
      setItems(data);
      return setTimeout(() => {
        setLoadingPaginate(false);
        setLoadingSearch(false);
      }, 500);
    }
    else if (selectedCategory !== '') {
      const res = await fetch(
      API_URL + `/products/products?page=1&limit=${limit}&groupGUID=${selectedCategory}`);
      const data = await res.json();
      const total = res.headers.get("x-total-count");
      setpageCount(Math.ceil(total / limit));
      setItems(data);
      return setTimeout(() => {
        setLoadingPaginate(false);
        setLoadingSearch(false);
      }, 500);
    }
    else if (selectedStock !== '') {
      const res = await fetch(
      API_URL + `/products/products?page=1&limit=${limit}&showInStock=${selectedStock}`);
      const data = await res.json();
      const total = res.headers.get("x-total-count");
      setpageCount(Math.ceil(total / limit));
      setItems(data);
      return setTimeout(() => {
        setLoadingPaginate(false);
        setLoadingSearch(false);
      }, 500);
    }
    else {
      const res = await fetch(
      API_URL + `/products/products?page=1&limit=${limit}`);
      const data = await res.json();
      const total = res.headers.get("x-total-count");
      setpageCount(Math.ceil(total / limit));
      setItems(data);
      setTimeout(() => {
        setLoadingPaginate(false);
        setLoadingSearch(false);
      }, 500);
    }
  };

  async function getCategories() {
    const res = await fetch(
      API_URL + `/products/categories`);
      const data = await res.json();
      setCategories(data);
      setTimeout(() => {
        setLoadingCategories(false);
      }, 500);
  };

  const fetchProducts = async (currentPage) => {
    if (selectedCategory !== '' && selectedStock !== '') {
        const res = await fetch(
        API_URL + `/products/products?page=${currentPage}&limit=${limit}&groupGUID=${selectedCategory}&showInStock=${selectedStock}`);
        const data = await res.json();
        setTimeout(() => {
          setLoadingSearch(false);
        }, 500);
        return data;
    }
    else if (selectedCategory !== '') {
      const res = await fetch(
        API_URL + `/products/products?page=${currentPage}&limit=${limit}&groupGUID=${selectedCategory}`);
        const data = await res.json();
        setTimeout(() => {
          setLoadingSearch(false);
        }, 500);
        return data;
    }
    else if (selectedStock !== '') {
      const res = await fetch(
        API_URL + `/products/products?page=${currentPage}&limit=${limit}&showInStock=${selectedStock}`);
        const data = await res.json();
        setTimeout(() => {
          setLoadingSearch(false);
        }, 500);
        return data;
    }
    else {
      const res = await fetch(
      API_URL + `/products/products?page=${currentPage}&limit=${limit}`);
      const data = await res.json();
      setTimeout(() => {
        setLoadingSearch(false);
      }, 500);
      return data;
    }
  };

  const onKeyDown = (event) => {
    if (event.key === 'Enter') {
      event.preventDefault();
      event.stopPropagation();
      fetchProducts(1);
      setLoadingSearch(true);
      setLoadingPaginate(true);
      getProducts();
    }
  }

  async function confirmFilter() {
    setLoadingSearch(true);
    setLoadingPaginate(true);
    getProducts();
  }

  async function clearFilter() {
    setSelectedCategory('');
    setSelectedStock('');
    setLoadingSearch(true);
    setLoadingPaginate(true);
    getProducts();
    setLoadingCategories(true);
    setTimeout(() => {
      setLoadingCategories(false);
    }, 500);
  }

  const handlePageClick = async (data) => {
    window.scrollTo(0, 0);
    setLoadingSearch(true);
    let currentPage = data.selected + 1;
    const commentsFormServer = await fetchProducts(currentPage);
    setItems(commentsFormServer);
  };
  useEffect(() => {
      setLoadingSearch(true);
      getCategories();
      getProducts();
  }, [limit]);

    return (
      <>
      <section className="vr-main" style={{height: 'auto', marginTop: 0}}>
          <Container>
            <Row md={12} className='d-flex justify-content-center'>
              <div className='d-flex' style={{marginBottom: '20px'}}>    
                <svg xmlns="http://www.w3.org/2000/svg" style={{marginTop: '2px', marginRight: '10px'}} className="icon icon-tabler icon-tabler-building-store" width={24} height={24} viewBox="0 0 24 24" stroke-width={2} stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round">
                  <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                  <path d="M3 21l18 0"></path>
                  <path d="M3 7v1a3 3 0 0 0 6 0v-1m0 1a3 3 0 0 0 6 0v-1m0 1a3 3 0 0 0 6 0v-1h-18l2 -4h14l2 4"></path>
                  <path d="M5 21l0 -10.15"></path>
                  <path d="M19 21l0 -10.15"></path>
                  <path d="M9 21v-4a2 2 0 0 1 2 -2h2a2 2 0 0 1 2 2v4"></path>
                </svg>
                <h4>Категория: { }
                {
                  categories.map((category) => {
                    return (
                        category.GUID === selectedCategory ? category.name : null
                    )
                  })
                }
                </h4>
              </div>
            </Row>
            <Row md={12} className="d-flex justify-content-center">
              <Col md={3}>
                <ListGroup>
                  <ListGroup.Item>
                    <svg style={{marginBottom: '5px', marginRight: '5px'}} xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-line-dashed" width="24" height="24" viewBox="0 0 24 24" strokeWidth="2" stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round">
                      <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                      <path d="M5 12h2"></path>
                      <path d="M17 12h2"></path>
                      <path d="M11 12h2"></path>
                    </svg>
                    Наличие товара
                    {
                      loadingCategories === true ?
                      <div className="form-control">
                        <Placeholder animation="glow" size="lg">
                          <Placeholder xs={12} size="lg" />
                        </Placeholder>
                      </div>
                      :
                      <Form.Select defaultValue={selectedStock} onChange={(e) => setSelectedStock(e.target.value)} aria-label="Default select example">
                        <option value={''}>Не выбрано</option>
                        <option value={'true'}>В наличии</option>
                        <option value={'false'}>Отсутствуют</option>
                      </Form.Select>
                    }
                  </ListGroup.Item>
                  <ListGroup.Item action onClick={() => confirmFilter()}>
                    <svg style={{marginBottom: '5px', marginRight: '5px'}} xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-filter-search" width={24} height={24} viewBox="0 0 24 24" strokeWidth={2} stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round">
                      <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                      <path d="M11.36 20.213l-2.36 .787v-8.5l-4.48 -4.928a2 2 0 0 1 -.52 -1.345v-2.227h16v2.172a2 2 0 0 1 -.586 1.414l-4.414 4.414"></path>
                      <path d="M18 18m-3 0a3 3 0 1 0 6 0a3 3 0 1 0 -6 0"></path>
                      <path d="M20.2 20.2l1.8 1.8"></path>
                    </svg>
                    Применить фильтр поиска
                  </ListGroup.Item>
                  <ListGroup.Item action onClick={() => clearFilter()}>
                    <svg style={{marginBottom: '5px', marginRight: '5px'}} xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-filter-x" width={24} height={24} viewBox="0 0 24 24" strokeWidth={2} stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round">
                      <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                      <path d="M13.758 19.414l-4.758 1.586v-8.5l-4.48 -4.928a2 2 0 0 1 -.52 -1.345v-2.227h16v2.172a2 2 0 0 1 -.586 1.414l-4.414 4.414v1.5"></path>
                      <path d="M22 22l-5 -5"></path>
                      <path d="M17 22l5 -5"></path>
                    </svg>
                    Очистить фильтр поиска
                  </ListGroup.Item>
                  <ListGroup.Item disabled active>
                    <svg style={{marginBottom: '5px', marginRight: '5px'}}  xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-info-hexagon" width="24" height="24" viewBox="0 0 24 24" strokeWidth="2" stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round">
                      <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                      <path d="M19.875 6.27c.7 .398 1.13 1.143 1.125 1.948v7.284c0 .809 -.443 1.555 -1.158 1.948l-6.75 4.27a2.269 2.269 0 0 1 -2.184 0l-6.75 -4.27a2.225 2.225 0 0 1 -1.158 -1.948v-7.285c0 -.809 .443 -1.554 1.158 -1.947l6.75 -3.98a2.33 2.33 0 0 1 2.25 0l6.75 3.98h-.033z"></path>
                      <path d="M12 9h.01"></path>
                      <path d="M11 12h1v4h1"></path>
                    </svg>
                    Не получается найти нужную запчасть или возникли вопросы?<br /><hr />Свяжитесь с нами удобным для вас способом и мы поможем подобрать запчасть или товар!
                  </ListGroup.Item>
                </ListGroup>
              </Col>
              <Col md={9} className='mt_products-all'>
                <Row className="d-flex justify-content-center gap-5">
                  {
                  loadingSearch === true ?
                  ['1', '2', '3', '4', '5', '6', '7', '8', '9'].map((expand) => (
                  <Card key={expand} style={{ width: '18rem' }}>
                    {/* <Card.Img variant="top" style={{objectFit: 'cover', width: '100%', marginTop: '13px'}} src={NoImage} /> */}
                    <div
                      className='rounded-top'
                      style={{background: '#E0E0E0', height: '262px', width: '100%', objectFit: 'cover', marginTop: '13px'}}
                    >
                      <Spinner style={{marginLeft: '112px', marginRight: 'auto', marginTop: '112px'}} animation="border" variant="primary" role="status">
                        <span className="visually-hidden">Loading...</span>
                      </Spinner>
                    </div>
                    <Card.Body>
                      <Placeholder as={Card.Title} animation="glow">
                        <Placeholder xs={6} />
                      </Placeholder>
                      <Placeholder as={Card.Text} animation="glow">
                        <Placeholder xs={7} /> <Placeholder xs={4} /> <Placeholder xs={4} />{' '}
                        <Placeholder xs={6} /> <Placeholder xs={8} />
                      </Placeholder>
                      <Placeholder.Button variant="primary" xs={6} />
                    </Card.Body>
                  </Card>
                  ))
                  :
                  items.map((item) => {
                    return (
                    <Card key={item.GUID} style={{ width: '18rem' }}>
                      <Card.Img variant="top" style={{objectFit: 'cover', width: '100%', marginTop: '13px'}} src={item.imgSrc === null ? NoImage : item.imgSrc} />
                      <Card.Body className="d-flex flex-wrap">
                        <Card.Title>{item.name}</Card.Title>
                        <div className="align-self-center">
                          <hr />
                          <Card.Text>
                            Цена: {item.price === 0 ? <strong>По запросу</strong> : <strong>{item.price} ₽</strong> }
                          </Card.Text>
                          <Card.Text>
                            В наличии: <strong>{item.balance}</strong> шт
                          </Card.Text>
                          <ButtonGroup aria-label="Basic example">
                            <Link to={`/products/${item.GUID}`} className="btn btn-primary">
                                Посмотреть
                            </Link>
                            {
                              item.balance !== 0 ?
                              <Button variant="outline-primary" onClick={() => addCart(item.GUID, item.name, item.price)}>В корзину</Button>
                              :
                              <Button variant="outline-dark" disabled>Отсутствует</Button>
                            }

                          </ButtonGroup>
                      </div>
                      </Card.Body>
                    </Card>
                    )
                  })
                  }
                </Row>
            </Col>
            </Row>
            {
              loadingPaginate === true ? null :
            <Row md={12} className='d-flex justify-content-center mt-4'>
              <Col md={4} className='d-flex justify-content-center mt-paginate'>
                <ReactPaginate
                  marginPagesDisplayed={2}
                  pageRangeDisplayed={3}
                  previousLabel={"Назад"}
                  nextLabel={"Вперед"}
                  pageCount={pageCount}
                  onPageChange={handlePageClick}
                  containerClassName="pagination"
                  breakClassName="page-item"
                  breakLabel={<div className="page-link">...</div>}
                  pageClassName="page-item"
                  previousClassName="page-item"
                  nextClassName="page-item"
                  pageLinkClassName="page-link"
                  previousLinkClassName="page-link"
                  nextLinkClassName="page-link"
                  activeClassName="active paginate-btn-active"
                />
              </Col>
            </Row>
            }
          </Container>
      </section>
      </>
    );
};

export default CategoriesPage;