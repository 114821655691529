import React, {useEffect} from 'react';
import {observer} from "mobx-react-lite";
import { Col, Container, Row } from 'react-bootstrap';
import SocialImg from '../components/img/social.png';
import { Link } from 'react-router-dom';
import API_URL from '../components/http';


const ContactsPage = () => {
  useEffect(() => {
  window.scrollTo(0, 0);
  })
  document.title = 'Контакты | Интернет-магазин автозапчастей - Мотор Трейд'
    return (
      <section className="mt-contacts">
        <Container>
          <div className='d-flex'>    
            <svg xmlns="http://www.w3.org/2000/svg" style={{marginTop: '2px', marginRight: '10px'}} className="icon icon-tabler icon-tabler-address-book" width={24} height={24} viewBox="0 0 24 24" strokeWidth={2} stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round">
              <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
              <path d="M20 6v12a2 2 0 0 1 -2 2h-10a2 2 0 0 1 -2 -2v-12a2 2 0 0 1 2 -2h10a2 2 0 0 1 2 2z"></path>
              <path d="M10 16h6"></path>
              <path d="M13 11m-2 0a2 2 0 1 0 4 0a2 2 0 1 0 -4 0"></path>
              <path d="M4 8h3"></path>
              <path d="M4 12h3"></path>
              <path d="M4 16h3"></path>
            </svg>
            <h4>Контакты организации</h4>
          </div>
        </Container>
        <Container style={{marginTop: '20px'}}>
          <div className="row align-items-md-stretch">
            <div className="col-md-6">
              <div className="h-100 p-5 text-white bg-primary rounded-3">
                <h4>ООО «МоторТрейд»</h4>
                <p>(Общество с ограниченной ответственностью «МоторТрейд»)</p>
                <hr />
                <div className="d-flex mt-4">
                  <svg xmlns="http://www.w3.org/2000/svg" style={{marginRight: '10px'}} className="icon icon-tabler icon-tabler-certificate" width={24} height={24} viewBox="0 0 24 24" strokeWidth={2} stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round">
                    <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                    <path d="M15 15m-3 0a3 3 0 1 0 6 0a3 3 0 1 0 -6 0"></path>
                    <path d="M13 17.5v4.5l2 -1.5l2 1.5v-4.5"></path>
                    <path d="M10 19h-5a2 2 0 0 1 -2 -2v-10c0 -1.1 .9 -2 2 -2h14a2 2 0 0 1 2 2v10a2 2 0 0 1 -1 1.73"></path>
                    <path d="M6 9l12 0"></path>
                    <path d="M6 12l3 0"></path>
                    <path d="M6 15l2 0"></path>
                  </svg>
                  <p>ИНН: 0000000000 КПП: 0000000000 ОГРН: 0000000000000</p>
                </div>
                <div className="d-flex">
                  <svg xmlns="http://www.w3.org/2000/svg" style={{marginRight: '10px'}} className="icon icon-tabler icon-tabler-map-2" width={24} height={24} viewBox="0 0 24 24" strokeWidth={2} stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round">
                    <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                    <path d="M12 18.5l-3 -1.5l-6 3v-13l6 -3l6 3l6 -3v7.5"></path>
                    <path d="M9 4v13"></path>
                    <path d="M15 7v5.5"></path>
                    <path d="M21.121 20.121a3 3 0 1 0 -4.242 0c.418 .419 1.125 1.045 2.121 1.879c1.051 -.89 1.759 -1.516 2.121 -1.879z"></path>
                    <path d="M19 18v.01"></path>
                  </svg>
                  <p>ХМАО-Югра, г. Сургут, ул. Нефтеюганское шоссе, д. 26</p>
                </div>
                <div className="d-flex">
                  <svg xmlns="http://www.w3.org/2000/svg" style={{marginRight: '10px'}} className="icon icon-tabler icon-tabler-device-landline-phone" width={24} height={24} viewBox="0 0 24 24" strokeWidth={2} stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round">
                    <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                    <path d="M20 3h-2a2 2 0 0 0 -2 2v14a2 2 0 0 0 2 2h2a2 2 0 0 0 2 -2v-14a2 2 0 0 0 -2 -2z"></path>
                    <path d="M16 4h-11a3 3 0 0 0 -3 3v10a3 3 0 0 0 3 3h11"></path>
                    <path d="M12 8h-6v3h6z"></path>
                    <path d="M12 14v.01"></path>
                    <path d="M9 14v.01"></path>
                    <path d="M6 14v.01"></path>
                    <path d="M12 17v.01"></path>
                    <path d="M9 17v.01"></path>
                    <path d="M6 17v.01"></path>
                  </svg>
                  <p>+7 (3462) 58-12-74</p>
                </div>
                <div className="d-flex">
                  <svg xmlns="http://www.w3.org/2000/svg" style={{marginRight: '10px'}} className="icon icon-tabler icon-tabler-mail" width={24} height={24} viewBox="0 0 24 24" strokeWidth={2} stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round">
                    <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                    <path d="M3 7a2 2 0 0 1 2 -2h14a2 2 0 0 1 2 2v10a2 2 0 0 1 -2 2h-14a2 2 0 0 1 -2 -2v-10z"></path>
                    <path d="M3 7l9 6l9 -6"></path>
                  </svg>
                  <p>3462556165@mail.ru</p>
                </div>
                <button className="btn btn-outline-light" type="button">Карточка предприятия</button>
              </div>
            </div>
            <div className="col-md-6">
              <div className="h-100 p-5 bg-light border rounded-3">
                <h4>Информация для клиентов</h4>
                <p>Вся необходимая информация компании для наших клиентов</p>
                <hr />
                <div className="d-flex mt-4">
                  <svg xmlns="http://www.w3.org/2000/svg" style={{marginRight: '10px'}} className="icon icon-tabler icon-tabler-clock-hour-9" width={24} height={24} viewBox="0 0 24 24" strokeWidth={2} stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round">
                    <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                    <path d="M12 12m-9 0a9 9 0 1 0 18 0a9 9 0 1 0 -18 0"></path>
                    <path d="M12 12h-3.5"></path>
                    <path d="M12 7v5"></path>
                  </svg>
                  <p>Ежедневно с 09:00 до 17:00</p>
                </div>
                <div className="d-flex">
                  <svg xmlns="http://www.w3.org/2000/svg" style={{marginRight: '10px'}} className="icon icon-tabler icon-tabler-external-link" width={24} height={24} viewBox="0 0 24 24" strokeWidth={2} stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round">
                    <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                    <path d="M12 6h-6a2 2 0 0 0 -2 2v10a2 2 0 0 0 2 2h10a2 2 0 0 0 2 -2v-6"></path>
                    <path d="M11 13l9 -9"></path>
                    <path d="M15 4h5v5"></path>
                  </svg>
                  <p>Политика конфиденциальности</p>
                </div>
                <div className="d-flex">
                  <svg xmlns="http://www.w3.org/2000/svg" style={{marginRight: '10px'}} className="icon icon-tabler icon-tabler-external-link" width={24} height={24} viewBox="0 0 24 24" strokeWidth={2} stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round">
                    <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                    <path d="M12 6h-6a2 2 0 0 0 -2 2v10a2 2 0 0 0 2 2h10a2 2 0 0 0 2 -2v-6"></path>
                    <path d="M11 13l9 -9"></path>
                    <path d="M15 4h5v5"></path>
                  </svg>
                  <p>Условия использования сайта</p>
                </div>
                <div className="d-flex">
                  <svg xmlns="http://www.w3.org/2000/svg" style={{marginRight: '10px'}} className="icon icon-tabler icon-tabler-external-link" width={24} height={24} viewBox="0 0 24 24" strokeWidth={2} stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round">
                    <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                    <path d="M12 6h-6a2 2 0 0 0 -2 2v10a2 2 0 0 0 2 2h10a2 2 0 0 0 2 -2v-6"></path>
                    <path d="M11 13l9 -9"></path>
                    <path d="M15 4h5v5"></path>
                  </svg>
                  <p>Процесс оформления заказа</p>
                </div>
                <a href="tel:+73462581274">
                  <button className="btn btn-outline-secondary" type="button">Позвонить нам</button>
                </a>
              </div>
            </div>
          </div>

          <div className="mt-4 border rounded-3" style={{position: 'relative', overflow: 'hidden'}}><a href="https://yandex.ru/maps/973/surgut/?utm_medium=mapframe&utm_source=maps" style={{color: '#eee', fontSize: '12px', position: 'absolute', top: '0px'}}>Сургут</a><a href="https://yandex.ru/maps/973/surgut/house/nefteyuganskoye_shosse_26/Y0oYcgZlTUUOQFhrfX53d3RqYg==/?indoorLevel=1&ll=73.415410%2C61.266896&utm_medium=mapframe&utm_source=maps&z=17.07" style={{color: '#eee', fontSize: '12px', position: 'absolute', top: '14px'}}>Нефтеюганское шоссе, 26 — Яндекс Карты</a><iframe src="https://yandex.ru/map-widget/v1/?from=mapframe&indoorLevel=1&ll=73.416120%2C61.266829&mode=usermaps&source=mapframe&um=constructor%3A501d53d1bea751f3fb62d30a8065c642706b08d419f397036ac54e3a01628c28&utm_source=mapframe&z=17" width="100%" height="400" frameBorder="1" allowFullScreen={true} style={{position: 'relative'}}></iframe></div>
        </Container>
      </section>
    );
};

export default observer(ContactsPage);